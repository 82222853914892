import React, {Suspense} from 'react';
// import './App.css';
import AppRoutes from './routes';
import './assets/sass/style.scss';
import MobileDetect from "mobile-detect";
import { ToastProvider  } from 'react-toast-notifications';
import {AppContextProvider} from "./AppContextProvider";
import DesktopHeader from "./components/DesktopHeader";
import {inNativeWebView} from "./ultils/helper";

function App() {
    var md = new MobileDetect(window.navigator.userAgent);

    let isIphone = md.is('iPhone');
    // let isMobile = md.phone();
    // let isTablet = md.tablet();

  // if (!isMobile && !isTablet)
  //   return <div>Hello world</div>;

  let classes = isIphone ? 'iphone-device' : 'android-device';

  return  <ToastProvider
      PlacementType={'top-center'}
      autoDismiss={true}
      autoDismissTimeout={5000}
  >
      <AppContextProvider>
          <div className={classes}>
              <Suspense fallback={<div>Đang tải...</div>} >
                  <AppRoutes />
              </Suspense>
          </div>
      </AppContextProvider>
      </ToastProvider>

}

export default App;
