import axios, {AxiosError, AxiosResponse} from "axios";
import {ADMIN_URL} from "../constances";
import {inNativeWebView} from "./helper";

export const setLocalAccessToken = (token: string) => {
    return window.localStorage.setItem('access_token', token)
}

export const getLocalToken = () => {
    return window.localStorage.getItem('access_token')
}

export const axiosInstance = axios.create({
    baseURL: ADMIN_URL,
    timeout: 30000,
    headers: {
        Authorization: 'Bearer ' + getLocalToken(),
        'Content-Type': 'application/json',
        accept: 'application/json'
    },
});

export const setToken = (token: string) => {
    token = token.trim()
    // @ts-ignore
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`
    setLocalAccessToken(token)
}


axiosInstance.interceptors.response.use( (response: AxiosResponse) => {
    return response.data;
}, (err: AxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const status = err.response?.status || 500;

    // we can handle global errors here
    switch (status) {
        // authentication (token related issues)
        case 401: {
            if (inNativeWebView()) {
                // @ts-ignore
                window.navigateToScreen('Auth__LoginScreen');
            }
            window.location.replace(`/dang-nhap`);
        }
    }

    return Promise.reject(err)
})




export const get = (path: string, params: object) => {
    ensureToken()
    if (!getLocalToken()) {
        if (inNativeWebView()) {
            // @ts-ignore
            window.navigateToScreen('Auth__LoginScreen');
        }

        return Promise.reject('aaa')
        window.location.replace(`/dang-nhap`);
    }
    return axiosInstance
        .get(path, { params: params })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
};

const ensureToken = () => {
    if (getLocalToken()) {
        let newToken = getLocalToken()
        // @ts-ignore
        newToken = newToken.trim()
        setToken(newToken)
        return;
    }
    // @ts-ignore
    if (window.accessToken !== undefined && window.accessToken) {
        // @ts-ignore
        setToken(window.accessToken)
    }
}



export const post = (path: string, data: object) => {
    try {
        ensureToken()
    } catch (e) {
    }


    if (!getLocalToken()) {
        if (inNativeWebView()) {
            // @ts-ignore
            window.navigateToScreen('Auth__LoginScreen');
        }
        window.location.replace(`/dang-nhap`);
    }

    return axiosInstance
        .post(path, data)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
};

export const put = (path: string, data: object) => {
    ensureToken()

    if (!getLocalToken()) {
        // window.location.replace(`/dang-nhap`);
        // @ts-ignore
        window.navigateToScreen('Auth__LoginScreen');
    }

    return axiosInstance
        .put(path, data)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
};

export const deleteRequest = (path: string) => {
    ensureToken()

    if (!getLocalToken()) {
        // @ts-ignore
        window.navigateToScreen('Auth__LoginScreen');
    }

    return axiosInstance
        .delete(path)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
};

export const update = (path: string, data: object) => {
    ensureToken()

    return axiosInstance
        .patch(path, data)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
};
export default axiosInstance;
