import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useHistory, Link} from "react-router-dom";
import {getLocalToken} from "../ultils/api";

function DesktopHeader() {
    const history = useHistory();
    const token = getLocalToken()

    return (<div className={'header-wrapper'}>
            <div className={'header-main'}>
                <Container style={{ textAlign: 'center' }}>
                <a className={'logo'} onClick={() => {
                    history.push('/')
                }} >
                    <img src={'/assets/img/logo-yellow.png'} />
                </a>
            </Container>

            </div>
            <div className={'header-bottom wide-nav nav-dark hide-for-medium'}>
                <Navbar expand="lg">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link
                                onClick={() => {
                                    history.push('/')
                                }}
                            >Trang chủ</Nav.Link>
                            <Nav.Link onClick={() => {
                                history.push('/nhap-ma')
                            }}>
                                Nhập mã
                            </Nav.Link>
                            {/*<Nav.Link onClick={() => {*/}
                            {/*    history.push('/lucky-draw')*/}
                            {/*}}>*/}
                            {/*   Vòng quay may mắn*/}
                            {/*</Nav.Link>*/}
                            <Nav.Link onClick={() => {
                                history.push('/dieu-khoan-su-dung')
                            }}>
                                Điều khoản
                            </Nav.Link>
                            {!token && <Nav.Link onClick={() => {
                                history.push('/dang-nhap')
                            }}>
                                Đăng nhập
                            </Nav.Link>}
                            {!token && <Nav.Link onClick={() => {
                                history.push('/dang-ky')
                            }}>
                                Đăng ký
                            </Nav.Link>}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                </Navbar>
            </div>
        </div>
    );
}

export default DesktopHeader;