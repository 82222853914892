import React from 'react';
import RoutesWithTransition from './RoutesWithTransition';
import {BrowserRouter, useHistory} from "react-router-dom";
import BottomMenu from "./components/BottomMenu";
import AppHeader from "./components/AppHeader";

function AppRoutes() {


   return <BrowserRouter>
        <RoutesWithTransition />
   </BrowserRouter>

}

export default AppRoutes;
