import React, {createContext } from 'react';

export const PageContext = createContext({
    pathName: '/',
    changePathName: (c: string) => {},
    title: '/',
    changeTitle: (c: string) => {},
    user: null
})

export const AppContextProvider = ({ children }: {
    children: any
}) => {
    const [pathName, setPathName] = React.useState('/')
    const [title, setTitle] = React.useState('')
    const [user, setUser] = React.useState(null)

    return (
        <PageContext.Provider
            value={{
                title,
                pathName: pathName,
                changePathName: (c: string) => setPathName(c),
                changeTitle: (c: string) => setTitle(c),
                user: user
            }}>{children}
        </PageContext.Provider>
    )
};


export const usePageContext = () => React.useContext(PageContext);
