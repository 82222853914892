export const isMobile = () => {
    return window.innerWidth <= 900
}

//ko work tre ios
export const numberWithCommas = (x:number) => {
    return x;
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const getOs = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const os = urlParams.get('os')
    if (!os) {
        return '';
    }
    return os
}

export const inNativeWebView = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const reactNativeWebView = urlParams.get('ReactNativeWebView')
    if (reactNativeWebView === 'true') {
        return true;
    }
    // @ts-ignore
    return window.ReactNativeWebView !== undefined
}
