import * as React from 'react'
import {Route, useLocation, useHistory} from 'react-router-dom';
const ForgotPasswordPage = React.lazy(() => import('./pages/forgot-password/ForgotPassword'))
const HomePage = React.lazy(() => import('./pages/Home'))
const HomePublicPage = React.lazy(() => import('./pages/public/HomePublic'))
// const UpdateAppPage = React.lazy(() => import('./pages/public/UpdateApp'))
const LoginPage = React.lazy(() => import('./pages/Login'))
const PromotionDetailPage = React.lazy(() => import('./pages/PromotionDetail'))
const PromotionPage = React.lazy(() => import('./pages/Promotion'))
const RegisterPage = React.lazy(() => import('./pages/Register'))
const Store = React.lazy(() => import('./pages/Store'))
const Account = React.lazy(() => import('./pages/account/Account'))
const Profile = React.lazy(() => import('./pages/account/Profile'))
const UserGift = React.lazy(() => import('./pages/account/UserGift'))
const PointsExchangeHistory = React.lazy(() => import('./pages/account/PointsExchangeHistory'))
const UserWheelGift = React.lazy(() => import('./pages/account/UserWheelGift'))
const LuckyDraw = React.lazy(() => import('./pages/LuckyDraw'))
const EnterCodeForgotPassword = React.lazy(() => import('./pages/forgot-password/EnterCodeForgotPassword'))
const UpdatePassword = React.lazy(() => import('./pages/account/UpdatePassword'))
const EnterCode = React.lazy(() => import('./pages/EnterCode'))
const CampaignPolicyPage = React.lazy(() => import('./pages/public/CampaignPolicy'))
const TocPage = React.lazy(() => import('./pages/public/Toc'))
const DownloadAppPage = React.lazy(() => import('./pages/public/DownloadApp'))
import SlideRoutes from 'react-slide-routes';
import { getLocalToken } from './ultils/api';
import BottomMenu from "./components/BottomMenu";
import {useEffect} from "react";
import animation from "react-slide-routes";
import {inNativeWebView} from "./ultils/helper";
import DesktopHeader from "./components/DesktopHeader";

function RoutesWithTransition() {
    // const isAuthenticationPage = ![
    //     '/dang-nhap',
    //     '/dang-ky',
    //     '/quen-mat-khau',
    //     '/nhap-ma-xac-nhan',
    // ].some((item) => {
    //     return location.toLowerCase().indexOf(item) > -1;
    // });
    //


    // const history = useHistory();
    // console.log('history', history)

    const path = window.location.pathname;
    // if (!getLocalToken() && path === '/') {
    //     window.location.replace(`/dang-nhap`);
    // }


    const publicUrls = [
        '/update-app',
        '/tai-app',
        '/huong-dan-nhap-ma',
        '/dieu-khoan-su-dung',
        '/dang-nhap',
        '/dang-ky',
        '/quen-mat-khau',
        '/nhap-ma-xac-nhan',
    ]

    if (publicUrls.indexOf(path) >-1) {

    } else {
        if (!inNativeWebView() && !getLocalToken()) {
            // window.location.replace(`/dang-nhap`);
            // return;
        }
    }
    const location = useLocation();

    return <div>
        {!inNativeWebView() && <DesktopHeader />}
    <SlideRoutes
        animation={'slide'}
        location={location}
    >
            <Route exact path="/account" children={<Account />} />
            <Route exact path="/public-home" children={<HomePublicPage />} />
            <Route exact path="/campaign-policy" children={<CampaignPolicyPage />} />
            <Route exact path="/dieu-khoan-su-dung" children={<TocPage />} />
            <Route exact path="/tai-app" children={<DownloadAppPage />} />
            <Route exact path="/update-app" children={<DownloadAppPage />} />

            <Route exact path="/ho-so" children={<Profile />} />
            <Route exact path="/lucky-draw" children={<LuckyDraw />} />
            <Route exact path="/nhap-ma" children={<EnterCode />} />
            <Route path="/tin-khuyen-mai/:slug" children={<PromotionDetailPage />} />
            <Route exact path="/tin-khuyen-mai" children={<PromotionPage />} />
            <Route exact path="/cua-hang-doi-qua" children={<Store />} />
            <Route exact path="/tai-khoan/qua-quay-thuong" children={<UserWheelGift />} />
            <Route exact path="/tai-khoan/qua-doi-diem" children={<UserGift />} />
            <Route exact path="/tai-khoan/lich-su-tich-diem" children={<PointsExchangeHistory />} />
            <Route exact path="/cap-nhat-mat-khau" children={<UpdatePassword />} />
            <Route exact path="/" children={<HomePage />} />

            <Route exact path="/quen-mat-khau" children={<ForgotPasswordPage />} />
            <Route exact path="/nhap-ma-xac-nhan/:phone" children={<EnterCodeForgotPassword />} />
            <Route exact path="/dang-ky" children={<RegisterPage />} />
            <Route exact path="/dang-nhap" children={<LoginPage />} />

        </SlideRoutes>
    </div>
}

export default RoutesWithTransition;
